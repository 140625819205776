import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './modules/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './modules/routing.module';
import { LayoutModule } from '@angular/cdk/layout';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { CfaNavigationModule } from '@cfa-angular/nav';
import { metaReducers, reducers } from './store/reducers/index';

import { GiveawayEffects } from './store/effects/giveaway.effects';
import { CreatedGiveawayEffects } from './store/effects/created-giveaway.effects';
import { MarketsEffects } from './store/effects/markets.effects';

import { UserService } from './shared/service/user.service';
import { GiveawayService } from './shared/service/giveaway.service';
import { HomeService } from './shared/service/home.service';

import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import {RoleGuard} from "./roleGuard.service";
import {NavigationService} from "./navigation.service";
import oktaConfig from './app.config';
import {OktaAuthModule, OktaConfig} from "@okta/okta-angular";
import {OktaAuth} from "@okta/okta-auth-js";
import {AuthInterceptor} from "./shared/okta/auth.interceptor";

import { environment } from '../environments/environment';
import * as LaunchDarkly  from 'launchdarkly-js-client-sdk';

const oktaAuth = new OktaAuth(oktaConfig.oidc);
const moduleConfig: OktaConfig = { oktaAuth };

export function setUp(navService: NavigationService, userService: UserService) {
  return () => {
    return navService.login().then(res => {
      if (res) {
        const sdkKey = environment.launchDarklySdkKey;
        const clientKey = environment.launchDarklyClientKey;
        const context: LaunchDarkly.LDContext = {
          kind: 'user',
          key: sdkKey
        };
        const client = LaunchDarkly.initialize(clientKey, context);
        client.on('ready', () => {
          return userService.getUserMarkets(client.allFlags().maintenanceCfa1gm);
        });
      }
    })
  }
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    FlexLayoutModule,
    CfaNavigationModule,
    AngularSvgIconModule.forRoot(),
    EffectsModule.forRoot([
      GiveawayEffects,
      CreatedGiveawayEffects,
      MarketsEffects,
    ]),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers
    }),
    StoreDevtoolsModule.instrument({ maxAge: 50 }),
    SharedModule,
    OktaAuthModule.forRoot(moduleConfig)
  ],
  providers: [
    UserService,
    GiveawayService,
    HomeService,
    HttpClient,
    HttpClientModule,
    NavigationService,
    RoleGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: setUp, deps: [NavigationService, UserService], multi: true }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

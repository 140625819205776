import * as markets from '../actions/markets.action';
import { filter, propEq } from 'ramda';

export interface State {
  authenticated: boolean,
  globalBanner: any,
  loading: boolean,
  maintenance: boolean,
  markets: any[],
  selectedMarket: any,
  userName: string
}

export const initialState: State = {
  authenticated: false,
  globalBanner: {},
  loading: true,
  maintenance: false,
  markets: [],
  selectedMarket: {
    marketGeoFences: []
  },
  userName: null
};

export function reducer(state = initialState, action: markets.Actions): State {
  switch (action.type) {
    case markets.GET_MARKETS: {
      return { ...state, loading: true };
    }

    case markets.GET_MARKETS_SUCCESS: {
      const { maintenanceEnabled, markets, userLoginName } = action.payload;
      let selectedMarket = markets ? markets[0] : {};
      let globalBanner = selectedMarket.notificationBanners ? selectedMarket.notificationBanners[0] : {};
      const maintenance = maintenanceEnabled;
      let userName = userLoginName ? userLoginName : '';

      return {
        ...state,
        authenticated: true,
        globalBanner,
        loading: false,
        maintenance,
        markets,
        selectedMarket,
        userName
      };
    }

    case markets.UPDATE_SELECTED_MARKET: {
      const selectedMarket = action.payload;
      return { ...state, selectedMarket };
    }

    case markets.UPDATE_SELECTED_MARKET_BY_ID: {
      const selectedMarketCode = action.payload;
      const selectedMarket = filter(propEq('marketCode', selectedMarketCode), state.markets)[0];
      const globalBanner = selectedMarket.notificationBanners ? selectedMarket.notificationBanners[0] : {};

      return { ...state, globalBanner, selectedMarket };
    }

    default: {
      return state;
    }
  }
}

export const getAuthenticated= (state: State) => state.authenticated;

export const getGeofences = (state: State) => state.selectedMarket.marketGeoFences;

export const getGlobalBanner = (state: State) => state.globalBanner;

export const getLoading = (state: State) => state.loading;

export const getMaintenance = (state: State) => state.maintenance;

export const getMarkets = (state: State) => state.markets;

export const getSelectedMarket = (state: State) => state.selectedMarket;

export const getUserName = (state: State) => state.userName;

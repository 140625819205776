import * as createdGiveaway from '../actions/created-giveaway.action';
import * as marketAction from '../actions/markets.action';
import { HomeConstants } from '../../home/home.constants';

export interface State {
  activeGiveaways: any;
  giveawayPerformances: any;
  pastGiveaways: any;
  scheduledGiveaways: any;
}

export const initialState: State = {
  activeGiveaways: {
    error: null,
    giveaways: [],
    loading: true
  },
  giveawayPerformances: {
    error: null,
    giveaways: [],
    loading: true
  },
  pastGiveaways: {
    error: null,
    giveaways: [],
    loading: true
  },
  scheduledGiveaways: {
    error: null,
    giveaways: [],
    loading: true
  }
};

export function reducer(state = initialState, action: createdGiveaway.Actions | marketAction.Actions): State {
  switch (action.type) {
    case createdGiveaway.GET_ACTIVE_GIVEAWAYS: {
      return {
        ...state,
        activeGiveaways: { loading: true }
      };
    }

    case createdGiveaway.GET_ACTIVE_GIVEAWAYS_SUCCESS: {
      const giveaways = action.payload;
      return {
        ...state,
        activeGiveaways: { giveaways, loading: false }
      };
    }

    case createdGiveaway.GET_CREATED_GIVEAWAYS_ERROR: {
      const giveawaysError = action.payload;
      switch (giveawaysError) {
        case HomeConstants.activeMarketsView: {
          return {
            ...state,
            activeGiveaways: {
              error: HomeConstants.activeGiveawayError,
              loading: false
            }
          };
        }

        case HomeConstants.futureMarketsView: {
          return {
            ...state,
            scheduledGiveaways: {
              error: HomeConstants.scheduledGiveawayError,
              loading: false
            }
          };
        }

        case HomeConstants.pastMarketsView: {
          return {
            ...state,
            pastGiveaways: {
              error: HomeConstants.pastGiveawayError,
              loading: false
            }
          };
        }

        default: {
          return { ...state };
        }
      }
    }

    case createdGiveaway.GET_FUTURE_GIVEAWAYS: {
      return {
        ...state,
        scheduledGiveaways: { loading: true }
      };
    }

    case createdGiveaway.GET_FUTURE_GIVEAWAYS_SUCCESS: {
      const giveaways = action.payload;
      return {
        ...state,
        scheduledGiveaways: { giveaways, loading: false }
      };
    }

    case createdGiveaway.GET_GIVEAWAY_PERFORMANCE: {
      return {
        ...state,
        giveawayPerformances: { loading: true }
      };
    }

    case createdGiveaway.GET_GIVEAWAY_PERFORMANCE_ERROR: {
      const error = action.payload;

      return {
        ...state,
        giveawayPerformances: { error, loading: false }
      };
    }

    case createdGiveaway.GET_GIVEAWAY_PERFORMANCE_SUCCESS: {
      const giveaways = action.payload;

      return {
        ...state,
        giveawayPerformances: { giveaways, loading: false }
      };
    }

    case createdGiveaway.GET_PAST_GIVEAWAYS: {
      return {
        ...state,
        pastGiveaways: { loading: true }
      };
    }

    case createdGiveaway.GET_PAST_GIVEAWAYS_SUCCESS: {
      const giveaways = action.payload;
      return {
        ...state,
        pastGiveaways: { giveaways, loading: false }
      };
    }

    case marketAction.UPDATE_SELECTED_MARKET_BY_ID: {
      return {
        ...state,
        activeGiveaways: { loading: true },
        giveawayPerformances: { loading: true },
        scheduledGiveaways: { loading: true }
      };
    }

    default: {
      return state;
    }
  }
}

export const getActiveGiveaways = (state: State) => state.activeGiveaways.giveaways;

export const getActiveGiveawaysError = (state: State) => state.activeGiveaways.error;

export const getActiveGiveawaysLoading = (state: State) => state.activeGiveaways.loading;

export const getGiveawayPerformances = (state: State) => state.giveawayPerformances.giveaways;

export const getGiveawayPerformancesError = (state: State) => state.giveawayPerformances.error;

export const getGiveawayPerformancesLoading = (state: State) => state.giveawayPerformances.loading;

export const getPastGiveaways = (state: State) => state.pastGiveaways.giveaways;

export const getPastGiveawaysError = (state: State) => state.pastGiveaways.error;

export const getPastGiveawaysLoading = (state: State) => state.pastGiveaways.loading;

export const getScheduledGiveaways = (state: State) => state.scheduledGiveaways.giveaways;

export const getScheduledGiveawaysError = (state: State) => state.scheduledGiveaways.error;

export const getScheduledGiveawaysLoading = (state: State) => state.scheduledGiveaways.loading;

export const environment = {
  production: true,
  env: 'production',
  appName: 'CFA One Giveaway Manager',
  uiRedirectUrl: 'https://chickfilaonegiveaway.cfahome.com/login/callback', // Production UI URL
  oktaClientId: '0oav11f00lSIWSbF41t7',
  oktaAuthUrl: 'https://cfahome.okta.com', // Production Okta Auth URL
  oktaAuthIssuer: 'https://cfahome.okta.com/oauth2/aus4i6zex3F52d4rn1t7',
  endSessionRedirectUri: "https://chickfilaonegiveaway.cfahome.com",
  cfa1gmApiUrl: 'https://chickfilaonegiveaway.dxe.my.chick-fil-a.com',
  urlsForAuthHeader: [
    'https://chickfilaonegiveaway.dxe.my.chick-fil-a.com'
  ],
  launchDarklySdkKey: 'sdk-21657a40-6671-42c6-bc81-e50800aa17dd',
  launchDarklyClientKey: '64dd3ca2a99b9d138da58ad8'
};

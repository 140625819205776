<span [ngSwitch]="col.type"
      [ngClass]="(col.mobile === 'title' && isMobile) ? 'mobile-data-table__title' : ''">

    <ng-container *ngSwitchCase="'MonthDay'">{{formatDate(getData()) | date:'M/d'}}</ng-container>
    <ng-container *ngSwitchCase="'Date'">{{formatDate(getData()) | date:'M/d/y'}}</ng-container>
    <ng-container *ngSwitchCase="'DayDate'">{{formatDate(getData()) | date:'EEE M/d/y'}}</ng-container>
    <ng-container *ngSwitchCase="'DateTime'">{{formatDate(getData()) | date:'M/d/yy h:mm a'}}</ng-container>
    <ng-container *ngSwitchCase="'DayDateTime'">
        {{formatDate(getData()) | date:'EEE M/d/yy h:mm a'}}
    </ng-container>
    <ng-container *ngSwitchCase="'currency'">
        {{getNumber() | currency:'USD':true:'1.2-2'}}
    </ng-container>

    <ng-container *ngSwitchCase="'percentage'">
        {{getNumber() | percent: '1.2-2'}}
    </ng-container>

    <ng-container *ngSwitchCase="'roundedPercentage'">
        {{getNumber() | percent: '1.0-0'}}
    </ng-container>

    <div *ngSwitchCase="'Status'" [ngClass]="getData() === 'Failed' ? 'ngx-data-table__failed-text' : ''">
        {{getData()}}
    </div>

    <ng-container *ngSwitchCase="'delete'">
        <img class="ngx-data-table__delete-icon"
             alt=""
             (click)="$event.stopPropagation(); deleteClicked()"
             src="https://www.cfacdn.com/img/Spotlight/Icons/Delete_RedIcon.png"/>
    </ng-container>

    <ng-container *ngSwitchCase="'edit'">
        <img class="ngx-data-table__edit-icon"
             alt=""
             (click)="$event.stopPropagation(); editClicked()"
             src="https://www.cfacdn.com/img/Spotlight/Icons/Edit_RedIcon.png"/>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="isMobile; then templateMobile; else templateDefault;"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'rewardGeofence'">
        <span *ngIf="isMobile; else templateDefault">
            <span [ngClass]="col.mobile === 'geofence' ? 'mobile-data-table__subtitle' : ''">{{getData()}}</span>
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'redemptionDateRange'">
        <span *ngIf="isMobile;">
            <span
              [ngClass]="col.mobile === 'title' ? 'mobile-data-table__title' : ''">Redemption Dates: {{getData()}}
            </span>
        </span>
    </ng-container>

    <ng-container *ngSwitchCase="'claimStartDate'">
        <span *ngIf="isSendNow()">Send Now Enabled</span>
        <span *ngIf="!isSendNow() && isMobile">
            <span
              [ngClass]="col.mobile === 'subtitle' ? 'mobile-data-table__subtitle' : ''">Claim Start Date: {{getData() || 'N/A'}}
            </span>
        </span>
        <span *ngIf="!isSendNow() && !isMobile" [innerHTML]="lineBreak(getData())"></span>
    </ng-container>

    <ng-container *ngSwitchCase="'claimEndDate'">
        <span *ngIf="!isMobile" [innerHTML]="lineBreak(getData())"></span>
    </ng-container>

    <ng-template #templateMobile>
        <span class="truncated">{{getData()}}</span>
    </ng-template>

    <ng-template #templateDefault>{{getData()}}</ng-template>
</span>
